import React, { useState, useEffect } from 'react';
import { getVolunteers } from '../services/volunteerService';
import {NavLink} from "react-router-dom";

const VolunteerList = () => {
    const [volunteers, setVolunteers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVolunteers = async () => {
            try {
                const response = await getVolunteers();
                console.log(response); // Log the response to check the data format
                setVolunteers(response.data); // Ensure the data is correctly set
            } catch (error) {
                setError('Error fetching volunteers');
                console.error('Error fetching volunteers:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchVolunteers();
    }, []);

    return (
        <div>
            <h1 className="text-3xl font-bold text-red-700 text-center">Volunteers</h1>
            <div><div className="newrequest"><NavLink to="/register-volunteer">New Volunteer</NavLink> </div></div>
            <br clear="all"/>
            {error && <p>{error}</p>}
            {!loading && volunteers.length === 0 && <p>No volunteers available</p>}
            <div>
                {!loading && volunteers.length > 0 && (
                    <ul>
                        {volunteers.map(volunteer => (
                            <li key={volunteer.id}>{volunteer.name} - {volunteer.skills}</li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default VolunteerList;
