import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import './NavBar.css';
import { useNavigate } from 'react-router-dom';
const NavBar = () => {
    const { user, logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const handleLogout = () => {
        logout();
        navigate('/login');
    }
    return (
        <nav className="bg-gray-800 p-4">
            <ul className="flex justify-center space-x-4">
                <li>
                    <NavLink
                        to="/"
                        exact
                        activeClassName="text-white bg-gray-900"
                        className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                    >
                        Home
                    </NavLink>
                </li>
                {user && user.role == 'admin' ? (
                    <li>
                        <NavLink
                            to="/admin/users"
                            activeClassName="text-white bg-gray-900"
                            className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        >
                            Users List
                        </NavLink>
                    </li>
                    ):(<></>)}
                {user ? (
                    <>
                        {/*<li>
                            <NavLink
                                to="/experts"
                                activeClassName="text-white bg-gray-900"
                                className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                            >
                                Experts
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/volunteers"
                                activeClassName="text-white bg-gray-900"
                                className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                            >
                                Volunteers
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/mentors"
                                activeClassName="text-white bg-gray-900"
                                className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                            >
                                Mentors
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/register-volunteer"
                                activeClassName="text-white bg-gray-900"
                                className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                            >
                                Register Volunteer
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/register-expert"
                                activeClassName="text-white bg-gray-900"
                                className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                            >
                                Register Expert
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/assign-mentor"
                                activeClassName="text-white bg-gray-900"
                                className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                            >
                                Assign Mentor
                            </NavLink>
                        </li>*/}
                        <li>
                            <NavLink
                                to="/profile"
                                activeClassName="text-white bg-gray-900"
                                className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                            >
                                Profile
                            </NavLink>
                        </li>
                        <li>
                            <button
                                onClick={handleLogout}
                                className="text-gray-300 hover:text-white px-3 rounded-md text-sm font-medium"
                            >
                                Logout
                            </button>
                        </li>
                    </>
                ) : (
                    <>
                    {/*<li>
                            <NavLink
                                to="/register"
                                activeClassName="text-white bg-gray-900"
                                className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                            >
                                Register
                            </NavLink>
                        </li>*/}
                        <li>
                            <NavLink
                                to="/login"
                                activeClassName="text-white bg-gray-900"
                                className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                            >
                                Login
                            </NavLink>
                        </li>
                    </>
                )}
            </ul>
        </nav>
    );
};

export default NavBar;
