import React, { useState, useEffect } from 'react';
import { getMentors } from '../services/mentorService';
import {NavLink} from "react-router-dom";

const MentorList = () => {
    const [mentors, setMentors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMentors = async () => {
            try {
                const response = await getMentors();
                console.log(response); // Log the response to check the data format
                setMentors(response.data); // Ensure the data is correctly set
            } catch (error) {
                setError('Error fetching mentors');
                console.error('Error fetching mentors:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMentors();
    }, []);

    return (
        <div>
            <h1 className="text-3xl font-bold text-red-700 text-center">Mentors</h1>
            <div>
                <div className="newrequest"><NavLink to="/assign-mentor">New Mentor</NavLink></div>
            </div>
            {error && <p>{error}</p>}
            {!loading && mentors.length === 0 && <p>No mentors available</p>}
            <div>
                {!loading && mentors.length > 0 && (
                    <ul>
                        {mentors.map(mentor => (
                            <li key={mentor.id}>Expert: {mentor.expert_id}, Volunteer: {mentor.volunteer_id}</li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default MentorList;
