import api from './api';

export const getMentors = async () => {
    const response = await api.get('/mentors');
    return response.data;
};

export const createMentor = async (mentor) => {
    const response = await api.post('/mentors', mentor);
    return response.data;
};

// Add more methods as needed
