import React, {useContext, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ExpertList from './components/ExpertList';
import VolunteerList from './components/VolunteerList';
import MentorList from './components/MentorList';
import Home from './components/Home';
import NavBar from './components/NavBar';
import VolunteerForm from './components/VolunteerForm';
import ExpertForm from './components/ExpertForm';
import MentorForm from './components/MentorForm';
import RegisterForm from './components/RegisterForm';
import LoginForm from './components/LoginForm';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider, AuthContext } from './context/AuthContext';
import Profile from "./components/Profile";
import { useLocation } from 'react-router-dom';
import Footer from "./components/Footer";
import UsersList from "./components/UsersList";
function App() {
    const { user, logout } = useContext(AuthContext);

    return (
        <AuthProvider>
        <Router basename="/svc">
            <div className="App">
                <header className="App-header">
                    <h1 className="text-3xl font-bold my-4">Two Hours A Week</h1>
                </header>
                <NavBar user={user} logout={logout} />
                <main className="p-4">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/register" element={<RegisterForm />} />
                        <Route path="/login" element={<LoginForm />} />
                        <Route path="/admin/users" element={<UsersList />} />
                        <Route
                            path="/experts"
                            element={
                                <ProtectedRoute>
                                    <ExpertList />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/volunteers"
                            element={
                                <ProtectedRoute>
                                    <VolunteerList />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/mentors"
                            element={
                                <ProtectedRoute>
                                    <MentorList />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/register-volunteer"
                            element={
                                <ProtectedRoute>
                                    <VolunteerForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/register-expert"
                            element={
                                <ProtectedRoute>
                                    <ExpertForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/assign-mentor"
                            element={
                                <ProtectedRoute>
                                    <MentorForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/profile"
                            element={
                                <ProtectedRoute>
                                    <Profile />
                                </ProtectedRoute>
                            }
                        />
                    </Routes>
                    <Footer />
                </main>
            </div>
        </Router>
        </AuthProvider>
    );
}

export default App;
