import api from './api';

export const getVolunteers = async () => {
    const response = await api.get('/volunteers');
    return response.data;
};

export const createVolunteer = async (volunteer) => {
    const response = await api.post('/volunteers', volunteer);
    return response.data;
};

// Add more methods as needed
