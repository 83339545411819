import React, { useState, useEffect } from 'react';
import { createMentor } from '../services/mentorService';
import { getExperts } from '../services/expertService';
import { getVolunteers } from '../services/volunteerService';
import './Form.css'; // Import the CSS file for styling

const MentorForm = () => {
    const [expertId, setExpertId] = useState('');
    const [volunteerId, setVolunteerId] = useState('');
    const [experts, setExperts] = useState([]);
    const [volunteers, setVolunteers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const expertsData = await getExperts();
                const volunteersData = await getVolunteers();
                console.log('Experts Data:', expertsData); // Log the response to check the data format
                console.log('Volunteers Data:', volunteersData); // Log the response to check the data format
                setExperts(expertsData.data); // Ensure the data is correctly set
                setVolunteers(volunteersData.data); // Ensure the data is correctly set
                setLoading(false);
            } catch (error) {
                console.error('Error fetching experts or volunteers:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const mentor = {
                expert_id: Number(expertId), // Ensure the ID is sent as a number
                volunteer_id: Number(volunteerId) // Ensure the ID is sent as a number
            };
            await createMentor(mentor);
            setMessage('Mentor assigned successfully');
            // Clear the form
            setExpertId('');
            setVolunteerId('');
        } catch (error) {
            setMessage('Error assigning mentor');
            console.error('Error assigning mentor:', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!Array.isArray(experts) || !Array.isArray(volunteers)) {
        return <div>Error: Data format is incorrect</div>;
    }

    return (
        <div className="max-w-lg mx-auto p-8 bg-white shadow-lg rounded-lg">
            <h1 className="text-2xl font-bold mb-4">Assign a Mentor</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div className="form-group">
                    <label className="block text-sm font-medium text-gray-700">Expert:</label>
                    <select
                        value={expertId}
                        onChange={(e) => setExpertId(e.target.value)}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                        <option value="">Select Expert</option>
                        {experts.map((expert) => (
                            <option key={expert.ID} value={expert.ID}>
                                {expert.name} - {expert.field}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label className="block text-sm font-medium text-gray-700">Volunteer:</label>
                    <select
                        value={volunteerId}
                        onChange={(e) => setVolunteerId(e.target.value)}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                        <option value="">Select Volunteer</option>
                        {volunteers.map((volunteer) => (
                            <option key={volunteer.ID} value={volunteer.ID}>
                                {volunteer.name} - {volunteer.skills}
                            </option>
                        ))}
                    </select>
                </div>
                <button type="submit" className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                    Assign Mentor
                </button>
            </form>
            {message && <p className="mt-4 text-center text-sm text-gray-600">{message}</p>}
        </div>
    );
};

export default MentorForm;
