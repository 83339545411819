import React, {useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';

const Home = () => {
    const location = useLocation();
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        if (token) {
            console.log('Token updated ...')
            localStorage.setItem('token', token);
            // Remove token from URL
            window.history.replaceState({}, document.title, "/");
        }
    }, [location]);
    return (
        <div className="max-w-4xl mx-auto p-8 bg-white shadow-lg rounded-lg">
            <h1 className="text-4xl font-bold mb-4">Introduction</h1>
            <p className="text-lg leading-relaxed mb-4">
                In a world where time is one of our most precious commodities, it can be easy to feel that every moment
                must be meticulously planned and utilized for personal gain. Yet, there's a profound truth that often
                goes unnoticed: the time we invest in others is the time we invest in ourselves.
            </p>
            <p className="text-lg leading-relaxed mb-4">
                Imagine the impact if each of us dedicated just two hours a week to a social cause. It might seem like a
                small commitment, but it holds the potential to create ripples of change that spread throughout our
                community. Volunteering doesn’t just benefit those in need; it transforms us, too.
            </p>
            <p className="text-lg leading-relaxed mb-4">
                When we give our time to support a cause, we foster a sense of connection and purpose. We step out of
                our routines and gain new perspectives, understanding the challenges and triumphs of others. This
                awareness fosters empathy and compassion, making our community a more caring and supportive place.
                Through selfless service with humility, we not only help others but also nurture our own spirits.
            </p>
            <p className="text-lg leading-relaxed mb-4">
            Volunteering for two hours a week is a powerful way to build stronger relationships. Whether it's tutoring children, assisting the elderly, cleaning up local parks, or supporting local food banks, the bonds we form with those we help and those we serve alongside can last a lifetime. These relationships enrich our lives, bringing joy and a sense of belonging that is irreplaceable.
            </p>
            <p className="text-lg leading-relaxed mb-4">
            Furthermore, volunteering strengthens our skills and introduces us to new ones. From teamwork and leadership to problem-solving and communication, the abilities we develop as volunteers are invaluable in both our personal and professional lives. These experiences can open doors and provide us with a renewed sense of confidence and accomplishment.
            </p>
            <p className="text-lg leading-relaxed mb-4">
            Let’s not forget the joy that comes from making a difference. There is an unparalleled satisfaction in seeing the direct impact of our efforts, in knowing that we are contributing to something greater than ourselves. These moments of fulfillment are what fuel our passion and remind us of our shared humanity.
            </p>
            <p className="text-lg leading-relaxed mb-4">
            Imagine the transformation we can achieve if each one of us commits to just two hours a week. That's a mere fraction of our time, yet it can bring about meaningful change. It's an opportunity to be part of something bigger, to leave a legacy of kindness, and to inspire others to follow suit. Through selfless service with humility, we embody the true spirit of community and make a lasting impact.
            </p>
            <p className="text-lg leading-relaxed mb-4">
            So, let's take that step. Let’s pledge those two hours a week to volunteer for a cause that resonates with us. Together, we can build a community that thrives on compassion, unity, and a shared commitment to making the world a better place. Your two hours can change a life. And in doing so, it just might change yours, too.
            </p>
        </div>
    );
};

export default Home;
