import React, { useState, useEffect } from 'react';
import { getUsers } from '../services/userService';
import {NavLink} from "react-router-dom";

const UsersList = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await getUsers();
                console.log(response); // Log the response to check the data format
                setUsers(response.data); // Ensure the data is correctly set
            } catch (error) {
                setError('Error fetching users list');
                console.error('Error fetching users list:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    return (
        <div>
            <h1 className="text-3xl font-bold text-red-700 text-center">Users List</h1>
            <br clear="all"/>
            {error && <p>{error}</p>}
            {!loading && users.length === 0 && <p>No users available</p>}
            <div>
                {!loading && users.length > 0 && (
                    <div className="grid grid-cols-3 gap-4">
                {users.map(user => (
                    <div key={user.id} className="bg-white p-4 shadow-md rounded-lg">
                        <h2 className="text-2xl font-bold text-red-600">{user.name}</h2>
                        <p><span className="text-amber-600 font-bold">Email </span>{user.email}</p>
                        <p><span className="text-amber-600 font-bold">Phone </span>{user.phone}</p>
                        <p><span className="text-amber-600 font-bold">Address </span>{user.address}</p>
                        <p><span className="text-amber-600 font-bold">Skills </span>{user.skills}</p>
                        <p><span className="text-amber-600 font-bold">Availability </span>{user.availability}</p>
                        <p><span className="text-amber-600 font-bold">Role </span>{user.role}</p>
                        <p><span className="text-amber-600 font-bold">Notes </span>{user.note}</p>
                    </div>
                ))}
                    </div>
                )}
            </div>

        </div>
    );
};

export default UsersList;
