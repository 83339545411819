import React, { useState, useEffect } from 'react';
import { getExperts } from '../services/expertService';
import {NavLink} from "react-router-dom";

const ExpertList = () => {
    const [experts, setExperts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchExperts = async () => {
            try {
                const response = await getExperts();
                console.log(response); // Log the response to check the data format
                setExperts(response.data); // Ensure the data is correctly set
            } catch (error) {
                setError('Error fetching experts');
                console.error('Error fetching experts:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchExperts();
    }, []);

    return (
        <div>
            <h1 className="text-3xl font-bold text-red-700 text-center">Experts</h1>
            <div>
                <div className="newrequest"><NavLink to="/register-expert">New Expert</NavLink></div>
            </div>
            {error && <p>{error}</p>}
            {!loading && experts.length === 0 && <p>No experts available</p>}
            <div>
                {!loading && experts.length > 0 && (
                    <ul>
                        {experts.map(expert => (
                            <li key={expert.id}>{expert.name} - {expert.field}</li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default ExpertList;
