import api from './api';

export const getExperts = async () => {
    const response = await api.get('/experts');
    return response.data;
};

export const createExpert = async (expert) => {
    const response = await api.post('/experts', expert);
    return response.data;
};

// Add more methods as needed
