import React, { createContext, useState, useEffect } from 'react';
import api, { setAuthToken } from '../services/api';
import Cookies from 'js-cookie';

const AuthContext = createContext();


const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setAuthToken(token); // Set the token in Axios defaults
            api.get('/user')
                .then(response => {
                    setUser(response.data.data); // Adjust based on the response structure
                })
                .catch(error => {
                    console.error('Error fetching user:', error);
                    localStorage.removeItem('token');
                });
        }
    }, []);

    const login = async (email, password) => {
        const response = await api.post('/login', { email, password });
        const { token } = response.data;
        localStorage.setItem('token', token);
        setAuthToken(token); // Set the token in Axios defaults
        const userResponse = await api.get('/user');
        setUser(userResponse.data.data); // Adjust based on the response structure
    };

    const logout = () => {
        localStorage.removeItem('token');
        Cookies.remove('oauthstate', { path: '/' });
        //document.cookie = "cookie_name=oauthstate; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        setAuthToken(null); // Remove the token from Axios defaults
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, setUser, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
