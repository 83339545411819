import axios from 'axios';
const host = window.location.host;
const api = axios.create({
    baseURL: 'https://'+host+'/api',
});

export const setAuthToken = (token) => {
    if (token) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete api.defaults.headers.common['Authorization'];
    }
};


export default api;
