import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';

const GoogleLoginButton = () => {
    const clientId = "499363084842-99m6o6cnbjpnjfa958c00fi1830bvkas.apps.googleusercontent.com"; // Replace with your Google client ID

    const onSuccess = async (response) => {
        try {
            const host = window.location.host;
            const res = await axios.get('https://'+host+'/auth/login');
            window.location.href = res.data.url;
        } catch (error) {
            console.error('Login failed!', error);
        }
    };

    const onError = () => {
        console.error('Login failed!');
    };

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <GoogleLogin
                onSuccess={onSuccess}
                onError={onError}
            />
        </GoogleOAuthProvider>
    );
};

export default GoogleLoginButton;
